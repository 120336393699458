import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Nav from "./Component/Nav";
import Courses from "./Component/Courses"; // Import your Courses component
import Analysis from "./Component/Analysis"; // Import your Analysis component
import Ticker from "./Component/Ticker";
import Brokers from "./Component/Brokers";
import MarketChart from "./Component/MarketChart";
import Fact from "./Component/Fact";
import IndianMarket from "./Component/IndianMarket";
import Trade from "./Component/Trade";
import Faq from "./Component/Faq";
import Newslater from "./Component/Newslater";
//import Social from "./Component/Social";
import Footer from "./Component/Footer";
import User from "./Component/User"; // Import your User component

function App() {
  return (
    <BrowserRouter>
      <Nav />
      <Ticker />
      <br />
      <br />
      <div className="slang"></div>
      <Routes>
        <Route path="/" />{" "}
        {/* Define the default component for the root route */}
        <Route path="/User" element={<User />} />{" "}
        {/* Fix casing and define User route */}
        <Route path="/courses" element={<Courses />} />
        <Route path="/analysis" element={<Analysis />} />
        <Route path="/brokers" element={<Brokers />} />
      </Routes>
      <MarketChart />
      <br />

      <Fact />
      <IndianMarket />
      <Trade />
      <Faq />
<Newslater />
      <hr />
      <Footer />
      <br />
      <br />
    </BrowserRouter>
  );
}

export default App;
