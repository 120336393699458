import React from "react";
import "./User.css";

export default function User() {
  return (
    <div className="sign-in-box">
      <h2 style={{ backgroundColor: "transparent", color: "black" }}>
        Welcome Traders
      </h2>{" "}
    
      <button className="sign-in-btn google-btn">
        <img
          src="https://img.icons8.com/color/48/000000/google-logo.png"
          alt="Google logo"
          className="icon"
        />
        Sign in with Google
      </button>
      <button className="sign-in-btn facebook-btn">
        <img
          src="https://img.icons8.com/color/48/000000/facebook-new.png"
          alt="Facebook logo"
          className="icon"
        />
        Sign in with Facebook
      </button>
      <button className="sign-in-btn twitter-btn">
        <img
          src="https://img.icons8.com/color/48/000000/twitter--v1.png"
          alt="Twitter logo"
          className="icon"
        />
        Sign in with Twitter
      </button>
      <br /><br />
    </div>
  );
}
