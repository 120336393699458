import React from "react";
import "./Analysis.css";

export default function Analysis() {
  return (
    <>
      <div>
        <button
          type="button"
          className="btn btn-outline-danger"
          style={{ fontSize: 20 }}
        >
          Daily Analysis & Youtube
        </button>
      </div>
      <div className="video-container">
      
        <div className="video-grid">
          <div className="video-box">
            <iframe
              src="https://www.youtube.com/embed/TpohdIcMbGY"
              title="How to Improve Trading Psychology"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <div className="video-box">
            <iframe
              src="https://www.youtube.com/embed/yXsupY5hizk?list=PLuJtGQuCL1NtsxoFa53HaRmYjwCS7xWhC"
              title="Mastering the EUR/USD Forex Trade: M Pattern Strategy Explained @Octa_broker  @Exness"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <div className="video-box">
            <iframe
              src="https://www.youtube.com/embed/hHQi_rszC3Y?list=PLuJtGQuCL1NtsxoFa53HaRmYjwCS7xWhC"
              title="Bullish Hammer Candlestick Pattern Explained: A Powerful Reversal Signal"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <div className="video-box">
            <iframe
              src="https://www.youtube.com/embed/TpohdIcMbGY"
              title="How to Improve Trading Psychology"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <div className="video-box">
            <iframe
              src="https://www.youtube.com/embed/wlpI47I1qdc"
              title='💰 Make $800 in Live Bitcoin Trading! 🚀 Join Now &amp; Boost Your Profits!"'
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <div className="video-box">
            <iframe
              width="914"
              height="514"
              src="https://www.youtube.com/embed/l4lnBi6EZtw"
              title="Mastering the W Chart Pattern | Analysis &amp; Insights | 5th August 2024"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>{" "}
          <div className="video-box">
            <iframe
              width="914"
              height="514"
              src="https://www.youtube.com/embed/LkjUchL9DG0"
              title="market crash to hoga har taraf se negative news hai. kyu hai watch the video"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>{" "}
          </div>
          <div className="video-box">
            <iframe
              width="914"
              height="514"
              src="https://www.youtube.com/embed/OursIbEeocM"
              title="From Zero to Hero: Master Trading with a Winning Psychology. Begin your 2024 trading journey"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}
