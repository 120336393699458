import React from "react";
import "./Broker.css";

export default function Brokers() {
  return (
    <>
      <div className="container">
        <div className="card">
          <img
            src="https://www.tradebackfx.com/wp-content/uploads/2023/04/octafx-logo-1.png"
            alt="Octa fx Logo"
          />
          <div className="card-body">
            <p>
              Trade in the Forex with Upstox! Sign up using the link below and
              receive free mentorship
            </p>
            <a
              href="https://my.octafx.com/open-account/?refid=ib34905482"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click Now
            </a>
          </div>
        </div>
        <div className="card">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHeYvHag12hkUY3fYtLnH5kfhdOXHAmOY_jg&s"
            alt="Upstox Logo"
          />
          <div className="card-body">
            <p>
              Trade in the Indian market with Upstox! Sign up using the link
              below and receive free mentorship
            </p>
            <a
              href="https://link.upstox.com/Tg7b"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click Now
            </a>
          </div>
        </div>
        <div className="card">
          <img
            src="https://media.licdn.com/dms/image/D5612AQH3BEiL-LraPg/article-cover_image-shrink_600_2000/0/1697092791333?e=2147483647&v=beta&t=ONcTXdmK25vR1DgXgUyPrqcc5u5ndU2oQENPG2auj40"
            alt="exness Logo"
            style={{ marginTop: -20 }}
          />
          <div className="card-body">
            <p>
              {" "}
              Trade in the forex with Exness! Sign up using the link below and
              receive free mentorship
            </p>
            <a
              href="https://one.exnesstrack.net/a/8anglrbi10"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click Now
            </a>
          </div>
        </div>
        <div className="card">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/b/bb/Groww_app_logo.png?20210615060423 "
            alt="Groww Logo"
          />
          <div className="card-body"> <br />
            <p>
              {" "}
              Trade in the Indian market with Upstox! Sign up using the link
              below and receive free mentorship
            </p>
            <a
              href="https://upstox.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click Now
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
